
import Vue from 'vue'
import $ from 'jquery/dist/jquery.slim'
import Geolocator from '@/lib/geolocator'

export default {
  name: 'AddressPicker',
  data: -> {
    address: {
      formatted: ''
      latitude: null,
      longitude: null,
      postal_code: '',
      suburb: ''
    },
    geocoder: null,
    loading: false,
    map_instance: null,
    map_position: null,
    open: false,
    address_model: { description: null, value: null },
    query_string: null,
    query_results: [],
    search: ''
  },

  computed: {
    default_map_position: ->
      {
        lat: Number(@$store.getters['application/default_position'].latitude),
        lng: Number(@$store.getters['application/default_position'].longitude)
      }

    visible: -> @$store.state.address_picker.open
  }

  watch: {
    map_position: (value) ->
      @geocode_mapped_position()

    open: (open) ->
      new Geolocator(@render_map, @render_map).locate() if open

    query_string: (q) ->
      return if not !!q
      if @skip_autocomplete then @skip_autocomplete = false else @fetch_predictions()

    visible: (value) ->
      @open = value
  }

  methods: {
    build_map_starting_position: (user_location) ->
      if user_location? and !user_location.code
        { lat: user_location.latitude, lng: user_location.longitude }
      else
        @default_map_position

    geocode_mapped_position: ->
      @loading = true
      @geocoder.geocode({
        location: @map_position,
        region: @$store.getters['application/country']
      })
      .then (response) =>
        place = response.results[0]
        if place
          @get_address_from_google_place(place)
          prediction = { description: place.formatted_address }
          @skip_autocomplete = true
          @query_results = [prediction]
          @address_model = prediction
        @loading = false
      .catch (error) ->
        console.error 'geocode_mapped_position', error
        @loading = false

    geocode_searched_place: ->
      @loading = true
      @geocoder.geocode({ placeId: @address_model.place_id })
      .then (response) =>
        place = response.results[0]
        if place
          @get_address_from_google_place(place)
          @map_instance.panTo(place.geometry.location)
          @map_instance.setZoom(15)
        @loading = false
      .catch (error) -> console.error 'geocode_searched_place', error

    fetch_predictions: ->
      @loading = true
      @autocomplete_service.getPlacePredictions({
        componentRestrictions: { country: @$store.getters['application/country'] },
        input: @query_string,
        types: ['geocode']
      })
      .then (response) =>
        @query_results = response.predictions || []
        @loading = false
      .catch (error) -> console.error 'fetch_predictions.catch', error

    pick_address: ->
      @$emit('picked', @address)
      @dismiss()

    dismiss: ->
      @$store.commit('address_picker/dismiss')

    render_map: (position) ->
      @map_instance = new window.google.maps.Map(@$refs.the_map, {
        zoom: 10,
        center: @build_map_starting_position(position),
        disableDefaultUI: true
      })
      google.maps.event.addListener @map_instance, 'idle', => @set_map_position()
      $('<div/>', { class: 'center-marker' }).appendTo($(@map_instance.getDiv()))

    set_map_position: ->
      position = @map_instance.getCenter()
      @map_position = { lat: position.lat(), lng: position.lng() }

    get_address_from_google_place: (place) ->
      @address.formatted = place.formatted_address
      @address.latitude = place.geometry.location?.lat()
      @address.longitude = place.geometry.location?.lng()
      @address.postal_code = place.address_components.find((c) -> 'postal_code' in c.types)?.long_name
      # @address.suburb = place.address_components.find((c) -> 'sublocality' in c.types)?.long_name
  },

  created: ->
    @geocoder = new window.google.maps.Geocoder()
    @autocomplete_service = new window.google.maps.places.AutocompleteService()
}
