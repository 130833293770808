export default class Geolocator

  constructor: (ok_callback, error_callback) ->
    @ok_callback = ok_callback
    @error_callback = error_callback

  locate: ->
    if navigator.geolocation
      navigator.geolocation.getCurrentPosition @success, @error, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000
      }
    else
      @error('Geolocate is feature not available')

  success: (position) =>
    @ok_callback?({ latitude: position.coords.latitude, longitude: position.coords.longitude })

  error: (error) =>
    @error_callback?(error)
